import Head from 'next/head';

export default function Custom404() {
  return (
    <>
      <Head>

        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png"></link>
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png"></link>
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png"></link>
        <link rel="icon" href="/images/favicon/favicon.ico" type="image/x-icon" />
        <title>Page not found</title>
      </Head>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2 style={{ fontSize: "10em" }}>404</h2>
              <div className="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="glyphicon glyphicon-home"></span>
                  Go To Home Page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
